import React, { FC, useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PathLocales, SUPPORTED_LOCALES } from '@internal/plugin-eapi-react';
import { useLocale } from '../../hooks';
import { Routes } from '../../constants/routes';

export const LocaleWrapper: FC = () => {
  const { locale: paramsLocale } = useParams();
  const { locale } = useLocale();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (paramsLocale === locale) return;

    const isSupportedParamsLocale = SUPPORTED_LOCALES.includes(paramsLocale as PathLocales);    
    const newPathname = isSupportedParamsLocale ? pathname.replace(`/${paramsLocale}/`, `/${locale}/`) : `/${locale}/${Routes.NOT_FOUND}`;
    
    navigate(newPathname, { replace: true });
  }, [locale, pathname, paramsLocale, navigate]);

  return <Outlet />;
};
