import jwt_decode from 'jwt-decode';
import { Language, OktaTokenLocale, SUPPORTED_OKTA_LOCALES } from '@internal/plugin-eapi-common';

export function getUserLocale(oktaAccessToken: string): Language {
  const decodedToken = jwt_decode<{ locale: OktaTokenLocale }>(oktaAccessToken);
  const locale = SUPPORTED_OKTA_LOCALES.includes(decodedToken.locale) ? decodedToken.locale : Language.EN_GB;
  return normalizeLocale(locale);
}

function normalizeLocale(locale: string): Language {
  if (locale.toLowerCase().startsWith('en')) {
    return Language.EN_GB;
  }

  return locale
    .toLowerCase()
    .replace('_', '-')
    .replace(/([a-z]+)-([a-z]+)/, (_match, p1, p2) => p1 + '-' + p2.toUpperCase()) as Language;
}