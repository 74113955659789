import React, { FC, useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { SignInButton } from '../../components/SignInButton';
import { Page } from '../../layout/Page';
import { useStyles } from './styles';
import { LogOutModeType } from '../../constants/auth';
import { Routes } from '../../constants/routes';
import { useLocale } from '../../hooks';

export const SignedOut: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { locale } = useLocale();
  const [isAutoMode] = useState(() => {
    const mode = window.localStorage.getItem('eapi-logout-mode');
    return mode === LogOutModeType.AUTO;
  });

  useEffect(() => {
    window.localStorage.clear();
  }, []);

  return isAutoMode ? (
    <Page>
      <Box textAlign="center">
        <Typography variant="overline">{t('eapi-timeout-notify-text')}</Typography>
        <Typography variant="h3">{t('eapi-signed-out-text')}</Typography>
        <Typography>{t('eapi-signed-out-info')}</Typography>
        <SignInButton className={classes.button} variant="contained">
          {t('eapi-log-back-in-text')}
        </SignInButton>
      </Box>
    </Page>
  ) : (
    <Navigate to={`/${locale}/${Routes.HOME}`} />
  );
};
