import { Kinds } from '@internal/plugin-eapi-common';

export const getMappedKind = (kind: string): string => {
  switch (kind) {
    case Kinds.DOMAIN: return 'Solution Area';
    case Kinds.SYSTEM: return 'Business Service';
    case Kinds.API: return 'Tech Service';
    case Kinds.GROUP: return 'Owner group';
    case Kinds.USER: return 'Person';
    default: return '';
  }
}