import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItem } from '@mui/material';
import { Typography, List } from '@material-ui/core';
import { filterApiListByEnv } from '@internal/plugin-eapi-react';
import { BooleanConfigType, DocumentType, getEntityTitle, SDSEnvironmentType } from '@internal/plugin-eapi-common';
import { Entity, RELATION_HAS_PART } from '@backstage/catalog-model';
import { InfoCard, Progress } from '@backstage/core-components';
import { useDevPortalApi, useEAPIRelatedEntities, useSubscription } from '../../../../../../../../hooks';
import { ApisTable } from '../ApisTable';
import { ApiCard } from '../ApiCard';
import { useStyles } from './styles';

export interface ApiDetailsProps {
  service: Entity;
}

export const ApiDetails: FC<ApiDetailsProps> = ({ service }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { subscription } = useSubscription();
  const connectedapis = subscription?.subscriptionDetails.connectedApis;
  const connectedApiEnv = (connectedapis && connectedapis[0].environmentType) || '';
  const devPortalApi = useDevPortalApi();
  const documentationType = subscription?.subscriptionDetails.isTrial ? DocumentType.TRIAL : undefined;
  const isDocumentationExist = service.metadata.annotations?.['backstage.io/techdocs-ref'] !== undefined;

  const handleSwaggerDownload = async (uuid: string) => {
    const link = document.createElement('a');
    await devPortalApi
      .getSwaggerInfo(uuid)
      .then((data) => {
        const blob = new Blob([JSON.stringify(data, null, '\t')], {
          type: 'text/json',
        });

        link.download = `${data.info.title || uuid}.json`;
        link.href = window.URL.createObjectURL(blob);
        link.dataset.downloadurl = ['text/json', link.download, link.href].join(':');

        const evt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true,
        });

        link.dispatchEvent(evt);
      })
      .catch((error) => {
        console.error('Swagger Info call error ', error);
      });
    link.remove();
  };

  const getPostmanCollectionFilePath = (techService: Entity, environmentType: string): string | undefined => {
    let env: 'prd' | 'npd' | undefined;

    switch (environmentType) {
      case SDSEnvironmentType.PROD:
        env = 'prd';
        break;
      case SDSEnvironmentType.NON_PROD:
      case SDSEnvironmentType.DEMO:
        env = 'npd';
        break;
      default:
        env = undefined;
    }

    const isPostmanCollectionEnabled =
      env && techService.metadata.annotations?.[`swissre.com/postman-collection-${env}`] === BooleanConfigType.ENABLED;

    const techServiceName = techService.metadata.name;

    return isPostmanCollectionEnabled ? `${techServiceName}/postman-collection-${env}` : undefined;
  };

  const {
    entities: techServices,
    loading,
    error,
  } = useEAPIRelatedEntities(service, {
    type: RELATION_HAS_PART,
    kind: 'api',
  });

  if (loading) {
    return (
      <InfoCard title={t("eapi-tech-services-text")}>
        <Progress />
      </InfoCard>
    );
  }

  if (error || !techServices) return null;

  let showNoApisMessage = true;
  for (let i = 0; i < techServices.length; i++) {
    const techService = techServices[i];
    const apiList = filterApiListByEnv(techService.spec?.apiList as any, connectedApiEnv);
    if (apiList.length > 0) {
      showNoApisMessage = false;
      break;
    }
  }

  return (
    <>
      {showNoApisMessage ? (
        <Typography align="left">{t("eapi-subs-apis-empty-state-text")}</Typography>
      ) : (
        <List>
          {techServices &&
            techServices.map((techService) => {
              const apiList = filterApiListByEnv(techService.spec?.apiList as any, connectedApiEnv);
              if (apiList.length === 0) return;
              const env = connectedApiEnv && connectedApiEnv;
              const postmanCollectionPath = getPostmanCollectionFilePath(techService, env);

              return (
                <ListItem className={classes.container} key={techService.metadata.name}>
                  <ApiCard
                    title={getEntityTitle(techService)}
                    name={service.metadata.name}
                    isDocExist={isDocumentationExist}
                    documentationType={documentationType}
                  >
                    <ApisTable
                      apis={apiList}
                      handleSwaggerDownload={handleSwaggerDownload}
                      postmanCollectionPath={postmanCollectionPath}
                    />
                  </ApiCard>
                </ListItem>
              );
            })}
        </List>
      )}
    </>
  );
};
