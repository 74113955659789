import React, { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { FlatRoutes } from '@backstage/core-app-api';
import { DEFAULT_LOCALE } from '@internal/plugin-eapi-react';
import { Routes as BaseRoutes } from './constants/routes';
import { ProtectedRoute } from './components/ProtectedRoute';
import { LocaleWrapper } from './components/LocaleWrapper';
import { Home } from './pages/Home';
import { Solutions } from './pages/Solutions';
import { Subscriptions } from './pages/Subscriptions';
import { SubscriptionData } from './pages/Subscriptions/components/SubscriptionData';
import { ApiAnalytics } from './pages/ApiAnalytics';
import { Guides } from './pages/Guides';
import { Guide } from './pages/Guide';
import { SwaggerInfo } from './pages/Swagger';
import { SolutionDetails } from './pages/SolutionDetails';
import { SignedOut } from './pages/SignedOut';
import { PageNotFound } from './pages/PageNotFound';
import { ApiActivity } from './pages/ApiAnalytics/components/ApiActivity';
import { ApiConsumption } from './pages/ApiAnalytics/components/ApiConsumption';

export const DevPortalRoutes: FC = () => {
  return (
    <FlatRoutes>
      <Route path="/" element={<Navigate to={`/${DEFAULT_LOCALE}/${BaseRoutes.HOME}`} replace />} />
      <Route path=":locale" element={<LocaleWrapper />}>
        <Routes>
          <Route path={BaseRoutes.HOME} element={<Home />} />
          <Route path={BaseRoutes.SIGNED_OUT} element={<SignedOut />} />
          <Route
            path={BaseRoutes.SOLUTIONS}
            element={
              <ProtectedRoute>
                <Solutions />
              </ProtectedRoute>
            }
          />
          <Route
            path={BaseRoutes.SOLUTION}
            element={
              <ProtectedRoute>
                <SolutionDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path={BaseRoutes.SUBSCRIPTIONS}
            element={
              <ProtectedRoute>
                <Subscriptions />
              </ProtectedRoute>
            }
          >
            <Route path={BaseRoutes.SUBSCRIPTION} element={<SubscriptionData />} />
          </Route>
          <Route
            path={BaseRoutes.API_ANALYTICS}
            element={
              <ProtectedRoute>
                <ApiAnalytics />
              </ProtectedRoute>
            }
          >
            <Route path={BaseRoutes.API_ACTIVITY} element={<ApiActivity />} />
            <Route path={BaseRoutes.API_CONSUMPTION} element={<ApiConsumption />} />
          </Route>
          <Route
            path={BaseRoutes.GUIDES}
            element={
              <ProtectedRoute>
                <Guides />
              </ProtectedRoute>
            }
          />
          <Route
            path={BaseRoutes.GUIDE}
            element={
              <ProtectedRoute>
                <Guide />
              </ProtectedRoute>
            }
          />
          <Route
            path={BaseRoutes.SWAGGER}
            element={
              <ProtectedRoute>
                <SwaggerInfo />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </FlatRoutes>
  );
};

DevPortalRoutes.displayName = 'DevPortalRoutes';
