import React, { FC, useCallback, memo, useState } from 'react';
import { useParams } from 'react-router';
import { useAsyncRetry } from 'react-use';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { Typography, Box, Button, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { SymmetricKey } from '@internal/plugin-eapi-common';
import { formatDateToUTC } from '@internal/plugin-eapi-react';
import { NotificationSnackBar } from '../../../../../../../../components/NotificationSnackBar';
import { useEncryptionApi, useLocale } from '../../../../../../../../hooks';
import { ConfirmationModal } from '../../../../../../../../components/ConfirmationModal';
import { ColoredText } from '../../../../../../../../components/ColoredText';
import { KeysListTable } from './components/KeysListTable';
import { GenerateKeyModal } from './components/GenerateKeyModal';

enum ModalType {
  Delete = 'delete',
  Generate = 'generate',
}
interface Notification {
  type: 'error' | 'success';
  message: string;
  open: boolean;
}

export const SymmetricKeys: FC = memo(() => {
  const { clientId } = useParams();
  const { language } = useLocale();
  const { t } = useTranslation();
  const encryptionApi = useEncryptionApi();

  const [openModal, setOpenModal] = useState<ModalType | null>(null);
  const [notification, setNotification] = useState<Notification | null>(null);
  const [keyIdToDelete, setKeyIdToDelete] = useState<string>('');
  const [generationLoading, setGenerationLoading] = useState<boolean>(false);
  const [generatedKeyData, setGeneratedKeyData] = useState<SymmetricKey | null>(null);

  if (!clientId) return;

  const {
    value: keyList,
    loading,
    error,
    retry,
  } = useAsyncRetry(async () => {
    return await encryptionApi.getSecrets(clientId);
  }, [clientId]);

  const handleNotificationClose = () => {
    setNotification({ type: 'success', message: '', open: false });
  };

  const handleDeleteKeyModalOpen = useCallback(
    (keyId: string) => () => {
      setOpenModal(ModalType.Delete);
      setKeyIdToDelete(keyId);
    },
    [],
  );

  const handleDeleteKeyModalClose = useCallback(() => {
    setOpenModal(null);
    setKeyIdToDelete('');
  }, []);

  const handleGenerateKeyModalOpen = useCallback(() => {
    setOpenModal(ModalType.Generate);
    setGeneratedKeyData(null);
    handleKeyGenerate();
  }, []);

  const handleGenerateKeyModaClose = useCallback(async () => {
    setOpenModal(null);
    retry();
  }, [retry, setOpenModal]);

  const handleError = (error: unknown) => {
    const errorMessage = error instanceof AxiosError ? t(error.response?.data?.message) : t('eapi-generic-dp-error');
    setNotification({ type: 'error', message: errorMessage, open: true });
  };

  const handleSuccess = (message: string) => {
    setNotification({ type: 'success', message: message, open: true });
  };

  const handleKeyDelete = useCallback(async () => {
    try {
      await encryptionApi.deleteSecret(keyIdToDelete, clientId);
      handleSuccess(t('eapi-cred-sym-delete-success'));
      await retry();
    } catch (error) {
      handleError(error);
    } finally {
      handleDeleteKeyModalClose();
    }
  }, [clientId, handleDeleteKeyModalClose, keyIdToDelete, encryptionApi]);

  const handleKeyGenerate = useCallback(async () => {
    try {
      setGenerationLoading(true);
      const data = await encryptionApi.addSecret(clientId);
      setGeneratedKeyData(data);
      handleSuccess(t('eapi-cred-sym-generate-success'));
    } catch (error) {
      handleError(error);
      setOpenModal(null);
    } finally {
      setGenerationLoading(false);
    }
  }, [clientId, encryptionApi]);

  const isMaxKeysAmount = keyList && keyList.length >= 2;
  const creationDate = keyList?.find((key) => key.id === keyIdToDelete)?.createdAt;

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {t('eapi-cred-sym-title-text')}
      </Typography>
      <KeysListTable keysData={keyList} onKeyDelete={handleDeleteKeyModalOpen} isLoading={loading} error={error} />
      <Box mt={3}>
        <Tooltip title={!loading && isMaxKeysAmount ? t('eapi-cred-sym-disable-generate-info') : ''} placement="top">
          <Box display="inline">
            <Button
              role="button"
              disabled={loading || isMaxKeysAmount}
              variant="outlined"
              onClick={handleGenerateKeyModalOpen}
              startIcon={<AddIcon />}
            >
              {t('eapi-cred-sym-generate-text')}
            </Button>
          </Box>
        </Tooltip>
      </Box>
      {notification && (
        <NotificationSnackBar
          open={notification.open}
          onClose={handleNotificationClose}
          message={notification.message}
          type={notification.type}
        />
      )}
      <ConfirmationModal
        open={openModal === ModalType.Delete}
        onClose={() => setOpenModal(null)}
        onConfirm={handleKeyDelete}
        title={t('eapi-cred-sym-delete-text')}
        question={t('eapi-cred-sym-delete-question-text')}
        note={t('eapi-cred-sym-delete-info')}
        confirmText={t('eapi-cred-sym-delete-text')}
      >
        <Box display="flex" justifyContent="center" alignItems="baseline" mt={6} mb={4} gridColumnGap={8}>
          <Typography variant="h5">{t('eapi-id-text')}</Typography>
          <ColoredText variant="body2">{keyIdToDelete}</ColoredText>
          <Typography variant="h5">{t('eapi-creation-date-text')}</Typography>
          <ColoredText variant="body2">{creationDate ? formatDateToUTC(creationDate, language) : 'n/a'}</ColoredText>
        </Box>
      </ConfirmationModal>
      <GenerateKeyModal
        open={openModal === ModalType.Generate}
        onConfirm={handleGenerateKeyModaClose}
        loading={generationLoading}
        secret={generatedKeyData?.secret ?? null}
      />
    </>
  );
});

SymmetricKeys.displayName = 'SymmetricKeys';
