import React, { FC } from 'react';
import { Grid, LinearProgress } from '@material-ui/core';
import { Routes } from '../../constants/routes';
import { Page } from '../../layout/Page';
import { Card } from '../../components/Card';
import { ImageMessage } from '../../components/ImageMessage';
import { useGuidesList, useLocale } from '../../hooks';
import { useTranslation } from 'react-i18next';

export const Guides: FC = () => {
  const { guidesList, loading, error } = useGuidesList();
  const { t } = useTranslation();
  const { locale } = useLocale();

  if (error) {
    return (
      <Page>
        <ImageMessage type="error" title={t("eapi-guides-error")} />
      </Page>
    );
  }

  return (
    <Page
      title={t("eapi-guides-title-text")}
      subtitle={t("eapi-guides-subitle-text")}
      description={t("eapi-guides-info")}
    >
      {loading ? (
        <LinearProgress />
      ) : (
        <Grid container spacing={4}>
          {(guidesList || []).map(({ name }) => {
            const href = `/${locale}/${Routes.GUIDES}/${name}`;

            return (
              <Grid key={name} item xs={12} sm={6} md={4} lg={3}>
                <Card href={href} title={t(`eapi-guide-${name}-title-text`)} description={t(`eapi-guide-${name}-description-text`)} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Page>
  );
};
