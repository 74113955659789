import apiAnalyticsFeature from '../../../../assets/dev-resources/APIAnalytics_APIsSwissReDeveloperPortal.jpg';
import seamlessSecureDataIntegration from '../../../../assets/dev-resources/SeamlessSecureDataIntegration_APIsSwissReDeveloperPortal.jpg';
import demoSandboxEnvironment from '../../../../assets/dev-resources/DemoSandboxAPIS_APIsSwissReDeveloperPortal.jpg';

export interface Resource {
  id: string;
  key: string;
  image: string;
  altText: string;
  webpImage: string;
}

export const RESOURCES: ReadonlyArray<Resource> = [
  {
    id: 'seamless-data-integration',
    image: seamlessSecureDataIntegration,
    altText: 'Code example of an API request call for the CatNet API (API assessing global catastrophic risks).',
    webpImage: '/dev-resources/SeamlessSecureDataIntegration_APIsSwissReDeveloperPortal.webp',
    key: "eapi-resources-data"
  },
  {
    id: 'demo-sandbox-environment',
    image: demoSandboxEnvironment,
    altText: 'API information and response sample of an API call.',
    webpImage: '/dev-resources/DemoSandboxAPIS_APIsSwissReDeveloperPortal.webp',
    key: "eapi-resources-demo"
  },
  {
    id: 'api-analytics',
    image: apiAnalyticsFeature,
    altText: 'Illustration of the API reporting functionality within the Swiss Re Developer Portal.',
    webpImage: '/dev-resources/APIAnalytics_APIsSwissReDeveloperPortal.webp',
    key: "eapi-resources-analytics"
  },
];
