import React from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme, Button } from '@material-ui/core';
import { useAuth, useDialog, useLocale } from '../../../../../../hooks';
import { useStyles } from './styles';
import { useNavigate } from 'react-router';
import { Routes } from '../../../../../../constants/routes';
import { useTranslation } from 'react-i18next';

// TODO: move constants to the separated file. Decide about the logic with lang keys (the User Story has created)
const HERO_CONTENT_DATA = [
  {
    id: 'hero-text-1',
    title: 'Innovate',
    description: 'Explore the possibilities unlocked by our APIs and leverage our data to drive your business growth.',
    key: 'eapi-hero-innovate-info'
  },
  {
    id: 'hero-text-2',
    title: 'Develop',
    description: "Access documentation, explore samples, utilise our demo environment, and seamlessly transition to production as soon as you're ready.",
    key: 'eapi-hero-develop-info'
  },
];

const LOGGED_IN_CONTENT = {
  key: 'eapi-ready-to-explore',
  title: 'Ready to explore?',
  description: 'Dive into our solutions and contact us for access.',
  button: 'Explore our APIs',
  mode: 'logged-in'
};

const PUBLIC_CONTENT = { 
  key: 'eapi-ready-to-explore',
  title: 'Ready to start?', 
  description: 'Sign up to access the Swiss Re Developer Portal.', 
  button: 'Sign up now',
  mode: 'public'
};

export const HeroContent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { handleOpen } = useDialog();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { locale } = useLocale();
  
  const handleExplore = () => navigate(`/${locale}/${Routes.SOLUTIONS}`);
  const data = user ? { ...LOGGED_IN_CONTENT, action: handleExplore } : { ...PUBLIC_CONTENT, action: handleOpen };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" className={classes.wrapper} mb={isMobile ? 3 : 0}>
      <Grid container spacing={isMobile ? 10 : 2}>
        {HERO_CONTENT_DATA.map(({ id, key }) => (
          <Grid item xs={12} md={4} key={id} className={classes.grid}>
            <Typography variant="h5" component='h3' gutterBottom className={classes.title}>
              {t(`${key}-title`)}
            </Typography>
            <Typography>{t(key)}</Typography>
          </Grid>
        ))}

        <Grid item container xs={12} md={4} direction="column" className={classes.grid}>
          <Typography variant="h3" gutterBottom>
          {t(`${data.key}-${data.mode}-title`)}
          </Typography>
          <Typography gutterBottom>{t(`${data.key}-${data.mode}-desc`)}</Typography>
          <Button variant="contained" className={classes.button} onClick={data.action}>
            {t(`${data.key}-${data.mode}-button`)}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
