import React from 'react';
import { Typography } from '@material-ui/core';
import { Section } from '../../../../layout/Section';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

export const CustomerTestimonials = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Section title={t('eapi-testimonials-title')}>
      <Typography align="center" className={classes.description}>
        “Liberty Specialty Markets has been using CatNet® for a number of years. We selected this tool for its market-leading catastrophe
        modelling, particularly for flood and surge perils. CatNet® leverages Swiss Re's technical knowledge with an approach that
        incorporates not just global data and analysis but also granular country-level data that allows us to better analyze these natural
        peril exposures.“
      </Typography>
      <Typography variant="body1" align="center" className={classes.author}>
        Crystal Ottaviano
      </Typography>
      <Typography variant="body2" align="center" className={classes.title}>
        Chief Risk Officer, Global Risk Solutions Liberty Mutual USA
      </Typography>
    </Section>
  );
};
