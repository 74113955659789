import React, { useEffect, useState, useCallback, MouseEvent } from 'react';
import { Outlet,useNavigate, useParams } from 'react-router';
import { useAsync } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Box, LinearProgress } from '@material-ui/core';
import { BooleanConfigType, Subscription, SubscriptionDetails } from '@internal/plugin-eapi-common';
import { Page } from '../../layout/Page';
import { Routes } from '../../constants/routes';
import { ImageMessage } from '../../components/ImageMessage';
import { SubscriptionPicker } from '../../components/SubscriptionPicker';
import { ToggleButton, ToggleButtonValue } from './components/ToggleButton';
import { SubscriptionContextProvider, usePathDetails, useSubscriptionApi } from '../../hooks';
import { SubscriptionChip } from '../../components/SubscriptionChip';

const TOGGLE_BUTTON_DEFAULT_VALUE: ToggleButtonValue = ToggleButtonValue.CONSUMPTION;

export const ApiAnalytics = () => {
  const navigate = useNavigate();
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription>();
  const [subscription, setSubscription] = useState<SubscriptionDetails>();
  const [toggleButtonValue, setToggleButtonValue] = useState<ToggleButtonValue>(TOGGLE_BUTTON_DEFAULT_VALUE);
  const subscriptionsApi = useSubscriptionApi();
  const { activeSegment } = usePathDetails();
  const { t } = useTranslation();
  const { locale } = useParams();  

  const pageTitle = t('eapi-analytics-text');
  const pageSubtitle = t('eapi-analytics-subtitle-text');

  const {
    value: fetchedSubscription,
    loading,
    error,
  } = useAsync(async () => {
    if (!selectedSubscription?.clientId) return;

    const subscription = await subscriptionsApi.getSubscriptionByClientId(selectedSubscription.clientId);
    const hasApiConsumptionAnnotation = subscription.services?.some(
      (service) => service.metadata.annotations?.['swissre.com/api-consumption'] === BooleanConfigType.ENABLED,
    );

    setSubscription(subscription);

    return {
      hasApiConsumption: hasApiConsumptionAnnotation,
      clientId: subscription.subscriptionDetails.clientId,
      isTrial: subscription.subscriptionDetails.isTrial,
      environmentType: subscription.subscriptionDetails.environmentType,
      name: subscription.subscriptionDetails.name,
    };
  }, [selectedSubscription?.clientId]);

  const handleSubscriptionPickerChange = useCallback((selectedSubscription: Subscription) => {
    setSelectedSubscription(selectedSubscription);

    activeSegment && navigate(`/${locale}/${Routes.API_ANALYTICS}`);
  }, [activeSegment, setSelectedSubscription, navigate, locale]);

  const handleToggleButtonChange = useCallback(
    (_: MouseEvent<HTMLElement>, newValue: ToggleButtonValue) => {
      if (newValue === null || !selectedSubscription?.clientId) return;

      setToggleButtonValue(newValue);
      navigate(`${selectedSubscription.clientId}/${newValue}`);
    },
    [selectedSubscription?.clientId, navigate, setToggleButtonValue],
  );

  // effect needed for keeping the toggle button in sync with the url path
  // in case if user navigates with the url path or browser back/forward button
  useEffect(() => {
    if (!activeSegment || toggleButtonValue === activeSegment || !fetchedSubscription?.hasApiConsumption) return;

    setToggleButtonValue(activeSegment as ToggleButtonValue);
  }, [activeSegment, toggleButtonValue, fetchedSubscription?.hasApiConsumption, setToggleButtonValue]);

  useEffect(() => {
    if (activeSegment || !selectedSubscription?.clientId || fetchedSubscription?.clientId !== selectedSubscription.clientId) return;

    navigate(
      `${selectedSubscription.clientId}/${
        fetchedSubscription.hasApiConsumption ? ToggleButtonValue.CONSUMPTION : ToggleButtonValue.ACTIVITY
      }`,
      {
        replace: true,
      },
    );
  }, [activeSegment, fetchedSubscription, navigate, selectedSubscription?.clientId]);

  if (error) {
    return (
      <Page title={pageTitle} subtitle={pageSubtitle}>
        <SubscriptionPicker onChange={handleSubscriptionPickerChange} />
        <ImageMessage title={t('eapi-analytics-error')} type="error" />
      </Page>
    );
  }

  if (loading || !selectedSubscription?.clientId || fetchedSubscription?.clientId !== selectedSubscription?.clientId)
    return (
      <Page title={pageTitle} subtitle={pageSubtitle}>
        <SubscriptionPicker onChange={handleSubscriptionPickerChange} />
        {loading && (
          <Box mt={4}>
            <LinearProgress data-testid="linear-progress" />
          </Box>
        )}
      </Page>
    );

  return (
    <Page title={pageTitle} subtitle={pageSubtitle}>
      <SubscriptionPicker onChange={handleSubscriptionPickerChange} />
      <>
        {fetchedSubscription?.hasApiConsumption && (
          <Box display="flex" justifyContent="center" mt={4}>
            <ToggleButton onChange={handleToggleButtonChange} value={toggleButtonValue} />
          </Box>
        )}
        <Box my={4}>
          <SubscriptionChip
            isTrial={fetchedSubscription.isTrial}
            environmentType={fetchedSubscription.environmentType}
            subscriptionName={fetchedSubscription.name}
          />
        </Box>
      </>
      <SubscriptionContextProvider subscriptionContext={{ subscription, setSubscription }}>
        <Outlet />
      </SubscriptionContextProvider>
    </Page>
  );
};
