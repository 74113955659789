import { useMemo } from 'react';
import axios from 'axios';
import { useApi, oktaAuthApiRef, discoveryApiRef } from '@backstage/core-plugin-api';
import { ValidateOktaIdToken } from '@internal/plugin-eapi-common';
import { LogOutModeType } from '../../constants/auth';
import { useLocale } from '../context';
import { Routes } from '../../constants/routes';

const baseUrl = window.location.origin;

export const useLogout = () => {
  const authApi = useApi(oktaAuthApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const { locale } = useLocale();

  const redirectUrl = useMemo(() => `/${locale}/${Routes.SIGNED_OUT}`, [locale]);

  const logout = async (mode: LogOutModeType) => {
    try {
      const oktaIdToken = window.localStorage.getItem('eapi-okta-id-token');
      await authApi.signOut();
      window.localStorage.setItem('eapi-logout-mode', mode);
      if (oktaIdToken) {
        const kid = getTokenKid(oktaIdToken);
        const url = await discoveryApi.getBaseUrl(`dev-portal/validate/id-token?kid=${kid}`);
        const res = await axios.get(decodeURIComponent(url));
        const response: ValidateOktaIdToken = res && res.data;
        if (response.isValid) {
          window.location.href = `${response.oktaLogoutUrl}?id_token_hint=${oktaIdToken}&post_logout_redirect_uri=${baseUrl}${redirectUrl}`;
          return;
        }
      }
      window.location.href = `${baseUrl}${redirectUrl}`;
    } catch (err) {
      console.error(err);
    }
  };

  return { logout };
};

function getTokenKid(idToken: string): string {
  const arrayToken = idToken.split('.');
  const header = arrayToken && atob(arrayToken[0]);
  const tokenHeader = JSON.parse(header);
  return tokenHeader && tokenHeader.kid;
}
