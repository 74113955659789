import React, { FC, PropsWithChildren } from 'react';
import { useMatch } from 'react-router';
import { Box } from '@material-ui/core';
import { Routes } from '../../../constants/routes';
import { PersistLogin } from '../../../components/PersistLogin';
import { useLocale } from '../../../hooks';

export const PageLayoutWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { locale } = useLocale();
  const isSignedOutRoute = useMatch(`/${locale}/${Routes.SIGNED_OUT}`);

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      {isSignedOutRoute ? <>{children}</> : <PersistLogin>{children}</PersistLogin>}
    </Box>
  );
};
