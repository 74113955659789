import { Language } from "@internal/plugin-eapi-common";

export enum PathLocales {
  PT = 'pt',
  EN = 'en',
}

export const SUPPORTED_LOCALES: ReadonlyArray<PathLocales> = [PathLocales.PT, PathLocales.EN];
export const DEFAULT_LOCALE: Readonly<PathLocales> = PathLocales.EN;

export const LANG_TO_LOCALE_MAPPING = {
  [Language.PT_BR]: PathLocales.PT,
  [Language.EN_GB]: PathLocales.EN,
} as const

export const LOCALE_TO_LANG_MAPPING = {
  [PathLocales.PT]: Language.PT_BR,
  [PathLocales.EN]: Language.EN_GB,
} as const;
