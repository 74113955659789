import React, { ChangeEvent, FC, useState } from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useAsync } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Box, LinearProgress, Tab, Tabs, Typography } from '@material-ui/core';
import { SubscriptionDetails } from '@internal/plugin-eapi-common';
import { Routes } from '../../../../constants/routes';
import { SubscriptionContextProvider } from '../../../../hooks/context';
import { ImageMessage } from '../../../../components/ImageMessage';
import { TabPanel } from '../../../../components/TabPanel';
import { TABS_CONFIGURATION, TAB_PATHS } from './constants';
import { useStyles } from './styles';
import { useSubscriptionApi, useTabHistorySync } from '../../../../hooks';
import { SubscriptionChip } from '../../../../components/SubscriptionChip';

export const SubscriptionData: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const subscriptionsApi = useSubscriptionApi();
  const [subscription, setSubscription] = useState<SubscriptionDetails>();
  const { clientId, tabName, locale } = useParams();
  const [tabValue, setTabValue] = useState(tabName || TAB_PATHS.ApisTab);
  useTabHistorySync(tabValue, setTabValue);

  if (!clientId) {
    return null;
  }

  const { loading, error } = useAsync(async () => {
    const subscriptionData = await subscriptionsApi.getSubscriptionByClientId(clientId);

    setSubscription(subscriptionData);
  }, [clientId]);

  const handleTabChange = (_event: ChangeEvent<{}>, value: string) => setTabValue(value);

  if (error) {
    return <ImageMessage title={t('eapi-analytics-error')} type="error" />;
  }

  if (loading) {
    return (
      <Box mt={4}>
        <LinearProgress data-testid="linear-progress" />
      </Box>
    );
  }

  const isTrial = subscription?.subscriptionDetails.isTrial;

  return (
    <SubscriptionContextProvider subscriptionContext={{ subscription, setSubscription }}>
      <Box display="flex" gridColumnGap={20} alignItems="center" my={4}>
        {subscription && (
          <SubscriptionChip
            isTrial={isTrial}
            environmentType={subscription.subscriptionDetails.environmentType}
            subscriptionName={subscription.subscriptionDetails.name}
          />
        )}
      </Box>
      <>
        <Box mb={5}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={tabValue}
            onChange={handleTabChange}
            aria-label="subscriptions page tabs"
          >
            {TABS_CONFIGURATION.map(({ key, path }, index) => (
              <Tab
                to={`/${locale}/${Routes.SUBSCRIPTIONS}/${clientId}/${path}`}
                component={RouterLink}
                id={`nav-tab-${index}`}
                label={t(key)}
                value={path}
                key={path}
                aria-controls={`nav-tabpanel-${index}`}
              />
            ))}
          </Tabs>
          {TABS_CONFIGURATION.map(({ panelComponent: PanelComponent, path }, index) => (
            <TabPanel value={path} index={index} key={path}>
              <PanelComponent />
            </TabPanel>
          ))}
        </Box>
        <Typography className={classes.acceptTerms}>{t('eapi-agreed-terms-text')}</Typography>
      </>
    </SubscriptionContextProvider>
  );
};
