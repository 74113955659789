import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { LinearProgress } from '@material-ui/core';
import { useDevPortalApi } from '../../api';
import i18n from '../../../config/i18n';
import { useLocale } from '../useLocale';
import { useAuth } from '../useAuth';

export const TranslationProvider: FC<PropsWithChildren> = ({ children }) => {
  const devPortalApi = useDevPortalApi();
  const { language } = useLocale();
  const { user } = useAuth();
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  useEffect(() => {
    if (!user || translationsLoaded) return;

    const loadTranslations = async () => {
      try {
        const translations = await devPortalApi.getTranslations();

        if (translations) {
          i18n.addResourceBundle(language, 'translation', translations, true, true);
          await i18n.reloadResources();
        }
      } catch (error) {
        console.error('Error loading translations:', error);
      } finally {
        setTranslationsLoaded(true);
      }
    };

    loadTranslations();
  }, [user, language, translationsLoaded]);

  if (!translationsLoaded) {
    return <LinearProgress data-testid="translations-loading" />;
  }

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};
