import { AppInsightsWrapper } from './appInsights/AppInsightsWrapper';
import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createApp } from '@backstage/app-defaults';
import { AppRouter } from '@backstage/core-app-api';
import { apis } from './apis';
import { swissReTheme } from './theme/swissre';
import { PageLayout } from './layout/PageLayout';
import { PageNotFound } from './pages/PageNotFound';
import { DevPortalRoutes } from './DevPortalRoutes';
import { AuthProvider, LoadingProvider, FeatureFlagsProvider, DialogProvider, LocaleProvider } from './hooks/context';

const devPortalApp = createApp({
  apis,
  components: {
    NotFoundErrorPage: PageNotFound,
  },
  themes: [
    {
      id: 'swissre-theme',
      title: 'SwissRe Theme',
      variant: 'light',
      Provider: ({ children }) => (
        <ThemeProvider theme={swissReTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
  ],
});

export default devPortalApp.createRoot(
  <AppInsightsWrapper>
    <AppRouter>
      <AuthProvider>
        <LocaleProvider>
          <LoadingProvider>
            <FeatureFlagsProvider>
              <DialogProvider>
                <PageLayout>
                  <DevPortalRoutes />
                </PageLayout>
              </DialogProvider>
            </FeatureFlagsProvider>
          </LoadingProvider>
        </LocaleProvider>
      </AuthProvider>
    </AppRouter>
  </AppInsightsWrapper>,
);
