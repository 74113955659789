import React, { FC, useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { Box, Button, Typography } from '@material-ui/core';
import { Routes } from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { useLocale } from '../../hooks';
import { DEFAULT_LOCALE } from '@internal/plugin-eapi-react/src/constants';
import { useStyles } from './styles';

export const PageNotFound: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { locale } = useLocale();
  const isNotFoundRoute = useMatch(`/${locale}/${Routes.NOT_FOUND}`);

  useEffect(() => {
    if (isNotFoundRoute) return;

    navigate(`/${locale || DEFAULT_LOCALE}/${Routes.NOT_FOUND}`);
  }, [navigate, locale]);

  const handleGoHomePage = () => navigate(`/${locale}/${Routes.HOME}`);

  return (
    <Box flex="1 0 auto" className={classes.wrapper}>
      <Typography variant="h6">{t('eapi-404-error')}</Typography>
      <Typography variant="h2">{t('eapi-page-not-found-text')}</Typography>
      <Button className={classes.button} variant="outlined" onClick={handleGoHomePage}>
        {t('eapi-page-not-found-info')}
      </Button>
    </Box>
  );
};
