import React, { FC, PropsWithChildren } from 'react';
import { useMatch } from 'react-router';
import { useLocale } from '../../hooks';
import { Routes } from '../../constants/routes';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { PageLayoutWrapper } from './PageLayoutWrapper';
import { SignUpDialog } from '../../components/SignUp/SignUpDialog';

export const PageLayout: FC<PropsWithChildren> = ({ children }) => {
  const { locale } = useLocale();
  const isNotFoundRoute = useMatch(`/${locale}/${Routes.NOT_FOUND}`);

  return (
    <PageLayoutWrapper>
      <Header />
      {children}
      {!isNotFoundRoute && <Footer />}
      <SignUpDialog />
    </PageLayoutWrapper>
  );
};
