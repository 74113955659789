import React, { FC, useState } from 'react';
import { useParams } from 'react-router';
import { useAsync } from 'react-use';
import { Divider, Grid, LinearProgress } from '@material-ui/core';
import { Solution } from '@internal/plugin-eapi-common';
import { TimeUnitRangeValue } from '@internal/plugin-eapi-common';
import { useApiAnalyticsApi, useSubscription } from '../../../../hooks';
import { SolutionsFilter } from './components/SolutionsFilter';
import { ModuleInfo } from './components/ModuleInfo';
import { ImageMessage } from '../../../../components/ImageMessage';
import { AlertBar } from '../../../../components/AlertBar';
import { TimePeriodFilter, TimePeriodOption } from '../TimePeriodFilter';
import { getAlertContent } from './utils/getAlertContent';
import { useTranslation } from 'react-i18next';

export const DEFAULT_DATE_FROM_FILTER: TimeUnitRangeValue = 'now-15d';

export const OPTIONS: TimePeriodOption<TimeUnitRangeValue>[] = [
  { value: 'now-1d', name: 'eapi-last-24-hours-text' },
  { value: 'now-7d', name: 'eapi-last-7-days-text' },
  { value: 'now-15d', name: 'eapi-last-15-days-text' },
  { value: 'now-30d', name: 'eapi-last-30-days-text' },
];


export const ApiConsumption: FC = () => {
  const { subscription } = useSubscription();
  const apiAnalyticsApi = useApiAnalyticsApi();
  const { clientId } = useParams();
  const [selectedSolution, setSelectedSolution] = useState<Solution>();
  const [timePeriodFilter, setTimePeriodFilter] = useState<TimeUnitRangeValue>(DEFAULT_DATE_FROM_FILTER);
  const { t } = useTranslation();

  if (!subscription) return null;

  const isTrial = subscription.subscriptionDetails.isTrial;
  const environmentType = subscription.subscriptionDetails.environmentType;

  const {
    value: solutions,
    loading,
    error,
  }: { value?: Solution[]; loading: boolean; error?: Error } = useAsync(async () => {
    if (!clientId) return;

    const solutions = await apiAnalyticsApi.getApiConsumption(clientId, timePeriodFilter);
    setSelectedSolution(solutions[0]);

    return solutions;
  }, [clientId, timePeriodFilter]);

  const { title, message } = getAlertContent(t, environmentType, isTrial, selectedSolution);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TimePeriodFilter defaultValue={DEFAULT_DATE_FROM_FILTER} setFilter={setTimePeriodFilter} options={OPTIONS} />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        {!loading && solutions && selectedSolution && (
          <SolutionsFilter solutions={solutions} selectedSolution={selectedSolution} onSolutionChange={setSelectedSolution} />
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider />
        {loading && <LinearProgress data-testid="linear-progress" />}
      </Grid>
      {!loading && selectedSolution && !!solutions?.length && (
        <>
          <Grid item xs={12}>
            <AlertBar variant="multiline" title={title} message={message} />
          </Grid>
          {selectedSolution.modules.map((module) => (
            <Grid item key={module.name}>
              <ModuleInfo module={module} period={timePeriodFilter} />
            </Grid>
          ))}
        </>
      )}
      {!loading && (error || solutions?.length === 0) && (
        <Grid item xs={12}>
          <ImageMessage
            type={error ? 'error' : 'info'}
            title={error ? t('eapi-analytics-consump-error') : t('eapi-analytics-consump-empty-state-text')}
          />
        </Grid>
      )}
    </Grid>
  );
};
