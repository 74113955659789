import { makeStyles } from '@material-ui/core';
import pageNotFoundImage from '../../assets/page-not-found.jpg';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 2,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.grey[300],
    backgroundImage: `linear-gradient(0deg, rgba(60, 65, 80, 0.65), rgba(60, 65, 80, 0.65)), url(${pageNotFoundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  button: {
    marginTop: theme.spacing(9),
  },
}));
