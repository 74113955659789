import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Link, useMediaQuery, useTheme } from '@material-ui/core';
import { Routes } from '../../constants/routes';
import { useAuth, useLocale } from '../../hooks';
import Logo from '../../components/Logo';
import { SignIn } from '../../components/SignIn';
import { useStyles } from './styles';

export const Header: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { user } = useAuth();
  const { t } = useTranslation();
  const { locale } = useLocale();

  return (
    <header className={classes.header}>
      <Box className={classes.leftItemsBox}>
        <Link className={classes.logo} to={`/${locale}/${Routes.HOME}`} component={RouterLink} aria-label="SwissRe logo">
          <Logo />
          {matches && (
            <Typography className={classes.logoTitle} component="span">
              Developer Portal
            </Typography>
          )}
        </Link>
        {user && (
          <>
            <Link className={classes.navLink} to={`/${locale}/${Routes.SOLUTIONS}`} component={RouterLink}>
              {t('eapi-solutions-text')}
            </Link>
            <Link className={classes.navLink} to={`/${locale}/${Routes.SUBSCRIPTIONS}`} component={RouterLink}>
              {t('eapi-subscriptions-text')}
            </Link>
            <Link className={classes.navLink} to={`/${locale}/${Routes.API_ANALYTICS}`} component={RouterLink}>
              {t('eapi-analytics-text')}
            </Link>
            <Link className={classes.navLink} to={`/${locale}/${Routes.GUIDES}`} component={RouterLink}>
              {t('eapi-guides-text')}
            </Link>
          </>
        )}
      </Box>
      <Box className={classes.rightItemsBox}>
        <SignIn />
      </Box>
    </header>
  );
};
