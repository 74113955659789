import React, { FC, useState, memo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Link, TextField, Typography } from '@material-ui/core';
import { FieldLabels, FieldNames, PUB_KEY_PLACEHOLDER } from './constants';
import { Routes } from '../../../../../../../../../../constants/routes';
import { GuidesNames } from '../../../../../../../../../../constants/guides';
import { useGuidesList, useLocale } from '../../../../../../../../../../hooks';
import { useStyles } from './styles';
import { useEncryptionApi } from '../../../../../../../../../../hooks';
import { AxiosError } from 'axios';

export interface UploadKeyFormProps {
  clientId: string;
  onCancel: () => void;
  onUploadKey: (type: 'success' | 'error', message: string) => void;
}

export interface UploadKeyFormData {
  [FieldNames.PUBLIC_KEY]: string;
}

export const UploadKeyForm: FC<UploadKeyFormProps> = memo(({ onCancel, clientId, onUploadKey }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { register, handleSubmit, reset } = useForm<UploadKeyFormData>({
    mode: 'onSubmit',
  });
  const encryptionApi = useEncryptionApi();
  const [loading, setLoading] = useState<boolean>(false);
  const { guidesList } = useGuidesList();
  const { locale } = useLocale();

  const handleModalClose = () => {
    reset();
    onCancel();
  };

  const onSubmit = async (formData: UploadKeyFormData) => {
    try {
      setLoading(true);

      await encryptionApi.setPublicKey(clientId, formData[FieldNames.PUBLIC_KEY]);

      onUploadKey('success', t('eapi-cred-asym-upload-success'));
    } catch (error) {
      const errorMessage = error instanceof AxiosError ? t(error.response?.data?.message) : t('eapi-generic-dp-error');
      onUploadKey('error', errorMessage);
    } finally {
      setLoading(false);
      handleModalClose();
    }
  };

  const guide = guidesList?.find(({ name }) => name === GuidesNames.KeyGenAuth);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        fullWidth
        variant="outlined"
        margin="normal"
        multiline
        minRows={8}
        required
        {...register(FieldNames.PUBLIC_KEY)}
        label={t(FieldLabels.PUBLIC_KEY)}
        placeholder={PUB_KEY_PLACEHOLDER}
        inputProps={{ 'aria-label': FieldLabels.PUBLIC_KEY }}
        focused
      />
      {guide && (
        <Typography className={classes.hintText} variant="body1" gutterBottom>
          {t('eapi-cred-asym-guide-text')}
          <Link className={classes.hintTextLink} href={`/${locale}/${Routes.GUIDES}/${guide.name}`} underline="always">
            {t(`eapi-guide-${guide.name}-title-text`)}
          </Link>
        </Typography>
      )}
      <Box mt={4} display="flex" justifyContent="center" gridColumnGap={16}>
        <Button variant="outlined" onClick={handleModalClose}>
          {t('eapi-cancel-text')}
        </Button>
        <Button variant="contained" type="submit" disabled={loading} startIcon={loading && <CircularProgress size={24} />}>
          {t('eapi-upload-text')}
        </Button>
      </Box>
    </form>
  );
});

UploadKeyForm.displayName = 'UploadKeyForm';
