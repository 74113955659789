import React from 'react';
import { useNavigate } from 'react-router';
import { Box, Button, Container, Typography } from '@material-ui/core';
import { useAuth, useDialog, useLocale } from '../../../../hooks';
import { Routes } from '../../../../constants/routes';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

const LOGGED_IN_CONTENT = {
  key: 'eapi-ready-to-explore',
  mode: 'logged-in'
};

const PUBLIC_CONTENT = { 
  key: 'eapi-ready-to-explore',
  mode: 'public'
};

export const ExploreSignUp = () => {
  const classes = useStyles();
  const { handleOpen } = useDialog();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { locale } = useLocale();
  
  const handleExplore = () => navigate(`/${locale}/${Routes.SOLUTIONS}`);
  const data = user ? { ...LOGGED_IN_CONTENT, action: handleExplore } : { ...PUBLIC_CONTENT, action: handleOpen };

  return (
    <Box className={classes.wrapper}>
      <Container maxWidth="md">
        <Typography variant="h2" gutterBottom>
          {t(`${data.key}-${data.mode}-${user ? 'title' : 'further-text'}`)}
        </Typography>
        <Typography variant="h3" gutterBottom>
          {t(`${data.key}-${data.mode}-desc`)}
        </Typography>
        <Button variant="outlined" color="inherit" className={classes.button} onClick={data.action}>
          {t(`${data.key}-${data.mode}-button`)}
        </Button>
      </Container>
    </Box>
  );
};
