export enum Language {
  PT_BR = 'pt-BR',
  EN_GB = 'en-GB',
}

export enum OktaTokenLocale {
  PT_BR = 'pt_BR',
  EN_UK = 'en_UK',
  EN_GB = 'en_GB',
  EN_US = 'en_US',
}

export const SUPPORTED_OKTA_LOCALES = [OktaTokenLocale.PT_BR, OktaTokenLocale.EN_GB, OktaTokenLocale.EN_UK, OktaTokenLocale.EN_US] as const;
