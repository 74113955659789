import { useParams } from "react-router";

export const usePathDetails = () => {
  const params = useParams();

  const pathSegments =params['*']?.split('/');
  const clientId = pathSegments?.[1];
  const activeSegment = pathSegments?.[2];

  return { clientId, activeSegment };
};