import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Subscription } from '@internal/plugin-eapi-common';
import { Page } from '../../layout/Page';
import { SubscriptionPicker } from '../../components/SubscriptionPicker';
import { TAB_PATHS } from './components/SubscriptionData/constants';
import { usePathDetails } from '../../hooks';

export const Subscriptions = () => {
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { clientId } = usePathDetails();

  const pageTitle = t('eapi-subscriptions-text');
  const pageSubtitle = t('eapi-subscriptions-subtitle-text');

  useEffect(() => {
    if (!selectedSubscription?.clientId || clientId) return;

    navigate(`${selectedSubscription.clientId}/${TAB_PATHS.ApisTab}`);
  }, [selectedSubscription?.clientId, clientId, navigate]);

  const handleSubscriptionChange = useCallback(
    (selectedSubscription: Subscription) => {
      setSelectedSubscription(selectedSubscription);

      navigate(`${selectedSubscription.clientId}/${TAB_PATHS.ApisTab}`);
    },
    [navigate, setSelectedSubscription],
  );

  return (
    <Page title={pageTitle} subtitle={pageSubtitle}>
      <SubscriptionPicker onChange={handleSubscriptionChange} />
      {selectedSubscription?.clientId && <Outlet />}
    </Page>
  );
};
