import React, { FC, useState } from 'react';
import { useParams } from 'react-router';
import { useAsync } from 'react-use';
import { Divider, Grid, LinearProgress, Typography } from '@material-ui/core';
import { Table } from '@backstage/core-components';
import { TimeUnitRangeValue } from '@internal/plugin-eapi-common';
import { ImageMessage } from '../../../../components/ImageMessage';
import { useApiAnalyticsApi, useLocale } from '../../../../hooks';
import { getColumns, options } from './columns';
import { TimePeriodFilter, TimePeriodOption } from '../TimePeriodFilter';
import { useTranslation } from 'react-i18next';

const DEFAULT_DATE_FROM_FILTER: TimeUnitRangeValue = 'now-1d';

export const OPTIONS: TimePeriodOption<TimeUnitRangeValue>[] = [
  { value: 'now-1d', name: 'eapi-last-24-hours-text' },
  { value: 'now-7d', name: 'eapi-last-7-days-text' },
  { value: 'now-15d', name: 'eapi-last-15-days-text' },
  { value: 'now-30d', name: 'eapi-last-30-days-text' },
];

export const ApiActivity: FC = () => {
  const apiAnalyticsApi = useApiAnalyticsApi();
  const [timePeriodFilter, setTimePeriodFilter] = useState<TimeUnitRangeValue>(DEFAULT_DATE_FROM_FILTER);
  const { clientId } = useParams();
  const { language } = useLocale();
  const { t } = useTranslation();

  const columns = getColumns(t, language);
  const {
    value: apiStats,
    loading,
    error,
  } = useAsync(async () => {
    if (!clientId) return;

    return await apiAnalyticsApi.getApiStats(clientId, timePeriodFilter);
  }, [clientId, timePeriodFilter]);

  const title = (
    <>
      <Typography variant="h5" gutterBottom component="p">{t('eapi-analytics-activ-title-text')}</Typography>
      <Typography variant="body2">
        <b>{apiStats?.totalHits}</b> {t('eapi-analytics-activ-subtitle-text')}
      </Typography>
    </>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TimePeriodFilter defaultValue={DEFAULT_DATE_FROM_FILTER} setFilter={setTimePeriodFilter} options={OPTIONS} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
        {loading && <LinearProgress />}
      </Grid>
      <Grid item xs={12}>
        {!loading && apiStats?.apiHitActivity &&  !!apiStats?.apiHitActivity?.length && (
          <Table
            data={apiStats.apiHitActivity}
            title={title}
            isLoading={loading}
            columns={columns}
            options={options}
            totalCount={apiStats.apiHitActivity.length}
          />
        )}
        {!loading && (error || apiStats?.apiHitActivity?.length === 0) && (
          <ImageMessage
            type={error ? 'error' : 'info'}
            title={error ? t('eapi-analytics-activ-error') : t('eapi-analytics-empty-state-text')}
          />
        )}
      </Grid>
    </Grid>
  );
};
