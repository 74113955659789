import React, { FC, PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { LinearProgress } from '@material-ui/core';
import {
  DEFAULT_LOCALE,
  LANG_TO_LOCALE_MAPPING,
  LOCALE_TO_LANG_MAPPING,
  PathLocales,
  SUPPORTED_LOCALES,
} from '@internal/plugin-eapi-react';
import { getUserLocale, Language } from '@internal/plugin-eapi-common';
import i18n from '../../../config/i18n';
import { useAuth } from '../useAuth';

const LocaleContext = createContext<{
  locale: PathLocales;
  language: Language;
  setLocale: (locale: PathLocales) => void;
} | null>(null);

export const LocaleProvider: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();
  const { user } = useAuth(); // Get user info, including Okta token
  const pathLocale = pathname.split('/')[1]?.toLowerCase() as PathLocales;
  const [loading, setLoading] = useState(true);

  const getInitialLocale = (): PathLocales => (SUPPORTED_LOCALES.includes(pathLocale) ? pathLocale : DEFAULT_LOCALE);

  const [locale, setLocale] = useState<PathLocales>(getInitialLocale);
  const [language, setLanguage] = useState<Language>(LOCALE_TO_LANG_MAPPING[getInitialLocale()]);

  useEffect(() => {
    const handleLanguageChange = async () => {
      const newLanguage = user?.oktaAccessToken ? getUserLocale(user.oktaAccessToken) : LOCALE_TO_LANG_MAPPING[locale];
      const newLocale = LANG_TO_LOCALE_MAPPING[newLanguage];

      if (newLocale !== locale) {
        setLocale(newLocale);
        setLanguage(newLanguage);
      }

      try {
        await i18n.changeLanguage(newLanguage);
      } catch (error) {
        console.error('Error while changing language:', error);
      } finally {
        setLoading(false);
      }
    };

    handleLanguageChange();
  }, [user?.oktaAccessToken, pathLocale]);

  const contextValue = useMemo(() => ({ locale, language, setLocale }), [locale, language]);

  if (loading) {
    return <LinearProgress data-testid="linear-progress" />;
  }

  return <LocaleContext.Provider value={contextValue}>{children}</LocaleContext.Provider>;
};

export const useLocale = () => {
  const context = useContext(LocaleContext);
  if (!context) throw new Error('Must be used within LocaleProvider');
  return context;
};
