import React from 'react';
import { Container, Typography, Grid, List, ListItem, useMediaQuery, useTheme } from '@material-ui/core';
import { RESOURCES } from './constants';
import { Section } from '../../../../layout/Section';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

export const DeveloperResources = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const { t } = useTranslation();

  const handleListItemClick = (index: number) => () => {
    if (isMobile) return;

    setSelectedIndex(index);
  };

  return (
    <Section title={t('eapi-developer-resources-text')} isBottomBorder={false}>
      <Container maxWidth="md" className={classes.sectionSubtitleContainer}>
        <Typography variant="h3" align="center">
          {t('eapi-resources-info')}
        </Typography>
      </Container>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <List component="div">
            {RESOURCES.map(({ id, key }, index) => (
              <ListItem
                key={id}
                button
                selected={!isMobile && selectedIndex === index}
                className={classes.button}
                onClick={handleListItemClick(index)}
                data-testid={id} 
              >
                <Typography variant="h5" component="h3" gutterBottom>
                  {t(`${key}-title-text`)}
                </Typography>
                <Typography variant="body1">{t(`${key}-subtitle-text`)}</Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
        {!isMobile && (
          <Grid item xs={12} md={6}>
            <picture>
              <source srcSet={RESOURCES[selectedIndex].webpImage} type="image/webp" />
              <img
                src={RESOURCES[selectedIndex].image}
                alt={RESOURCES[selectedIndex].altText}
                className={classes.image}
                {...{ fetchpriority: 'high' }}
              />
            </picture>
          </Grid>
        )}
      </Grid>
    </Section>
  );
};
