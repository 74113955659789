import { Language } from '@internal/plugin-eapi-common';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './en-GB.json';
import ptTranslations from './pt-BR.json';

i18n.use(initReactI18next).init({
  fallbackLng: Language.EN_GB,
  load: 'all',
  nonExplicitSupportedLngs: true,
  supportedLngs: [Language.EN_GB, 'en', Language.PT_BR, 'pt'],
  resources: {
    [Language.EN_GB]: {
      translation: enTranslations,
    },
    [Language.PT_BR]: {
      translation: ptTranslations,
    },
  },
  debug: false,
  interpolation: {
    escapeValue: false,
  },  
});

export default i18n;
