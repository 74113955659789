import React, { useContext, useMemo } from 'react';
import { useApi, oktaAuthApiRef } from '@backstage/core-plugin-api';
import { useNavigate } from 'react-router-dom';
import { EmailValidator, getUserLocale } from '@internal/plugin-eapi-common';
import { LANG_TO_LOCALE_MAPPING } from '@internal/plugin-eapi-react';
import { Routes } from '../../../constants/routes';
import { AuthContextValue, Props, LoggedInUser } from '../../../auth/types';

const authContextDefaults: AuthContextValue = {
  user: {} as LoggedInUser,
  setUser: () => null,
  signIn: () => null,
};

const AuthContext = React.createContext<AuthContextValue>(authContextDefaults);

export const AuthProvider = ({ children }: Props) => {
  const [user, setUser] = React.useState<LoggedInUser>();
  const navigate = useNavigate();
  const authApi = useApi(oktaAuthApiRef);

  // call this function when you want to authenticate the user
  const signIn = async (targetUrls: Record<string, Routes>) => {
    try {
      const identityResponse = await authApi.getBackstageIdentity({
        instantPopup: true,
      });

      if (!identityResponse) {
        throw new Error(`The provider is not configured to support sign-in`);
      }

      const profile = await authApi.getProfile();

      if (!profile) {
        throw new Error(`The profile information did not load correctly`);
      }

      const oktaIdToken = await authApi.getIdToken();
      const oktaAccessToken = await authApi.getAccessToken();
      setUser({
        identity: identityResponse,
        profile,
        oktaAccessToken,
      });
      const targetUrl = getRedirectRoute(targetUrls, profile?.email as string);
      window.localStorage.setItem('eapi-okta-id-token', oktaIdToken);

      const oktaTokenLocale = getUserLocale(oktaAccessToken);
      const newLocale = LANG_TO_LOCALE_MAPPING[oktaTokenLocale];

      navigate(`/${newLocale}/${targetUrl}`);
    } catch (error: any) {
      console.error(error.message);
    }
  };

  const value = useMemo(
    () => ({
      user,
      setUser,
      signIn,
    }),
    [user],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

function getRedirectRoute(targetUrls: Record<string, Routes>, email: string): string {
  const emailValidation = new EmailValidator();
  const isInternalUser = emailValidation.isValidSwissReEmail(email);
  return isInternalUser ? targetUrls.internalUser : targetUrls.externalUser;
}
