import { useNavigate } from 'react-router-dom';
import { useApi, oktaAuthApiRef } from '@backstage/core-plugin-api';
import { useAuth } from '../context/useAuth';
import { useLogout } from '../useLogout';
import { LoggedInUser } from "../../auth/types";
import { Routes } from '../../constants/routes';
import { LogOutModeType } from '../../constants/auth';
import { useLocale } from '../context';

export const useRefreshToken = () => {
    const { setUser } = useAuth();
    const { logout } = useLogout();
    const authApi = useApi(oktaAuthApiRef);
    const navigate = useNavigate();
    const { locale } = useLocale();

    const refresh = async () => {
        let newUserSession = {} as LoggedInUser;

        try {
            const identityResponse = await authApi.getBackstageIdentity({
                optional: true,
            });

            if (!identityResponse) {
                throw new Error('User not logged in')
            };

            const profile = await authApi.getProfile();

            if (!profile) {
                throw new Error(`The profile information did not load correctly`);
            }

            const oktaAccessToken = await authApi.getAccessToken();
            newUserSession = {
                identity: identityResponse,
                profile,
                oktaAccessToken
            };
            setUser(newUserSession);

        } catch (error) {
            console.error('Refresh token error:: ' + error);
            const oktaIdToken = window.localStorage.getItem('eapi-okta-id-token');
            /**
             * Presence of okta idToken in localstorage denotes that the user was logged-in and the refresh call encountered an error mostly
             * because the refresh token is expired by now, hence we are force logging-out the user and showing them a timeout message
             * with the option to log back in, for any other unprecendented error we redirect user to home.
             */
            if (oktaIdToken) {
              await logout(LogOutModeType.AUTO);
            } else {
              navigate(`/${locale}/${Routes.HOME}`);
            }
        }

        return newUserSession;
    }

    return { refresh };
};